import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import Language from "@material-ui/icons/Language";
import DashboardPage from "../views/Dashboard/Dashboard.js";
import Masalar from "../views/Masalar/masalar";
import GelenSiparisler from "../views/Siparisler/GelenSiparisler.js";
import Campaign from "../views/Settings/Campaign.js";
import Menu from "../views/Menu/Menu.jsx";
import TableSettings from "../views/Masalar/tableSettings.jsx";
import RestaurantSettings from "../views/Settings/RestaurantSettings.js";
import New_Masalar from "../views/Masalar/new_masalar.jsx";
import NewDashboard from "../views/Dashboard/NewDashboard";
import Stok from "../views/Stok/Stok.js";
import Muhasebe from "../views/Muhasebe/Muhasebe.js";
import OrdersTable from "../views/Tablolar/Siparisler";
import SonAramalar from "../views/Arama/SonAramalar.js";

export const routesWithUserSystem = [
  {
    path: "/rapor",
    name: "Ana Sayfa",
    rtlName: "لوحة القيادة",
    icon: Person,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/siparisler",
    name: "Gelen Siparişler",
    icon: "content_paste",
    component: GelenSiparisler,
    layout: "/admin",
  },
  {
    path: "/stok",
    name: "Stok",
    icon: "content_paste",
    component: Stok,
    layout: "/admin",
  },
  {
    path: "/muhasebe",
    name: "Muhasebe",
    icon: "content_paste",
    component: Muhasebe,
    layout: "/admin",
  },
  /*
  {
    path: "/typography",
    name: "asd",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/",
    name: "Stok",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/",
    name: "Muhasebe",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/",
    name: "İstatistik / Raporlama",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },*/
  {
    path: "/menu",
    name: "Menü",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: Menu,
    layout: "/admin",
  },
  {
    path: "/masalar",
    name: "deneme",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: New_Masalar,
    layout: "/admin",
  },
  {
    path: "/",
    name: "dash",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: NewDashboard,
    layout: "/admin",
  },
  {
    path: "/ayarlar/masa",
    name: "Masa Ayarları",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: TableSettings,
    layout: "/admin",
  },
  {
    path: "/ayarlar",
    name: "Restoran Ayarları",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RestaurantSettings,
    layout: "/admin",
  },
  {
    path: "/siparis-listesi",
    name: "Geçmiş Siparişler",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: OrdersTable,
    layout: "/admin",
  },

  /*{
    path: "/premium",
    name: "Premium'a Yükselt",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: null,
    layout: "/admin",
  },*/
];
const routes = [
  {
    path: "/rapor",
    name: "Ana Sayfa",
    rtlName: "لوحة القيادة",
    icon: Person,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/siparisler",
    name: "Gelen Siparişler",
    icon: "content_paste",
    component: GelenSiparisler,
    layout: "/admin",
  },
  {
    path: "/stok",
    name: "Stok",
    icon: "content_paste",
    component: Stok,
    layout: "/admin",
  },
  {
    path: "/muhasebe",
    name: "Muhasebe",
    icon: "content_paste",
    component: Muhasebe,
    layout: "/admin",
  },
  {
    path: "/menu",
    name: "Menü",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: Menu,
    layout: "/admin",
  },
  {
    path: "/masalar",
    name: "deneme",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: New_Masalar,
    layout: "/admin",
  },
  {
    path: "/",
    name: "dash",
    rtlName: "إخطارات",
    icon: RestaurantMenuIcon,
    component: NewDashboard,
    layout: "/admin",
  },
  {
    path: "/ayarlar/masa",
    name: "Masa Ayarları",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: TableSettings,
    layout: "/admin",
  },
  {
    path: "/ayarlar",
    name: "Restoran Ayarları",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RestaurantSettings,
    layout: "/admin",
  },
  {
    path: "/siparis-listesi",
    name: "Geçmiş Siparişler",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: OrdersTable,
    layout: "/admin",
  },
  {
    path: "/son-aramalar",
    name: "Son Aramalar",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: SonAramalar,
    layout: "/admin",
  },

];

export default routes;
