import React, { useContext, useEffect, useState } from "react";
import { useFirestore } from "react-firebase-hooks/firestore";
import { Table, Pagination, Form, Row, Col } from "react-bootstrap";
import { firestore } from "../../utils/firebase";
import { AdminContext } from "../../layouts/Admin";
import { useHistory } from "react-router-dom";

function OrdersTable() {
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const history = useHistory();
  const [menuObj, setMenuObj] = useState(null);
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderType, setOrderType] = useState("orders");
  const cafe = localStorage.getItem("name");

  const goBack = () => {
    history.push("/admin");
  };

  const calculateToplam = (order) => {
    if (order.arama || order.paket) return order.total;
    let total = 0.0;
    order.cart?.map((c) => {
      total += parseFloat(c.total) + (c.optPrice || 0);
    });

    return total.toFixed(2) + " TL";
  };
  const calculateTableTotal = () => {
    let total = 0.0;
    items.map((order) => {
      if (order.arama || order.paket) {
        total += parseFloat(order.total);
      } else {
        order.cart?.map((c) => {
          total += parseFloat(c.total) + (c.optPrice || 0);
        });
      }
    });
    return total.toFixed(2) + " TL";
  };

  useEffect(() => {
    firestore
      .collection(`Restaurants`)
      .doc(cafe)
      .onSnapshot((doc) => {
        let getMenu = doc.data().Menu;
        getMenu.sort((a, b) => {
          if (a.sortOrder < b.sortOrder) return -1;
          else return 1;
        });
        getMenu = getMenu.filter((m) => m.active == 1);

        setMenuObj(
          Object.assign(
            {},
            ...getMenu
              .map((m) => m.items.map((item) => ({ [item.id]: item })))
              .flat()
          )
        );
      });
  }, []);
  useEffect(() => {
    try {
      const unsubscribe = firestore
        .collection(
          `Restaurants/${cafe}/${
            orderType == "phone-orders" ? "paket-orders" : orderType
          }`
        )
        .where("date", ">=", new Date(new Date(startDate).setHours(0, 0, 0, 0)))
        .where(
          "date",
          "<=",
          new Date(new Date(endDate).setHours(23, 59, 59, 999))
        )
        .orderBy("date")
        .startAt((page - 1) * 20)
        .limit(20)
        .onSnapshot(
          (snapshot) => {
            const items = snapshot.docs.map((doc) => doc.data());
            if (orderType == "phone-orders") {
              setItems(items.filter((a) => a.arama != null));
            } else setItems(items.filter((a) => a.arama == null));
            setLoading(false);
          },
          (error) => {
            setError(error);
            setLoading(false);
          }
        );
      return () => unsubscribe();
    } catch {
      return;
    }
  }, [page, startDate, endDate, orderType]);
  const itemCount = items.length;
  const pageCount = itemCount > 0 ? Math.ceil(itemCount / 20) : 1;

  if (loading) {
    return (
      <p
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          marginLeft: "50%",
        }}
      >
        Loading...
      </p>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  const endDateOnChange = (value) => {
    try {
      var date = new Date(value);
      setEndDate(date.toISOString().slice(0, 10));
    } catch (error) {
      setEndDate(value);
    }
  };
  const startDateOnChange = (value) => {
    try {
      var date = new Date(value);
      setStartDate(date.toISOString().slice(0, 10));
    } catch (error) {
      setStartDate(value);
    }
  };
  return (
    <div style={{ width: "80%", marginTop: 40 }} className="mx-auto">
      <div>
        <div className="btn btn-danger mt-3 mb-3" onClick={goBack}>
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            style={{ width: "2vw" }}
          ></i>
        </div>
      </div>
      <div>
        <div
          className="btn btn-primary mt-3 mb-3"
          style={{
            border: orderType == "paket-orders" ? "3px solid black" : "",
          }}
          onClick={() => {
            setOrderType("paket-orders");
          }}
        >
          Paket Sipariş
        </div>

        <div
          className="btn btn-secondary"
          style={{
            marginLeft: 10,
            border: orderType == "orders" ? "3px solid black" : "",
          }}
          onClick={() => {
            setOrderType("orders");
          }}
        >
          Masa Sipariş
        </div>

        <div
          className="btn btn-success"
          style={{
            marginLeft: 10,
            border: orderType == "phone-orders" ? "3px solid black" : "",
          }}
          onClick={() => {
            setOrderType("phone-orders");
          }}
        >
          Telefon Sipariş
        </div>
      </div>
      <Form>
        <Form.Group as={Row} controlId="dateRange">
          <Form.Label column sm={2}>
            Tarih Aralığı
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => startDateOnChange(e.target.value)}
            />
          </Col>
          <Col sm={1} className="text-center" style={{ lineHeight: "2.5" }}>
            -
          </Col>
          <Col sm={4}>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => endDateOnChange(e.target.value)}
            />
          </Col>
        </Form.Group>
      </Form>
      <Table striped bordered hover style={{ marginTop: 20 }}>
        <thead>
          <tr>
            <th>Zaman</th>
            <th>İçerik</th>
            <th>Numara</th>
            <th>Toplam Tutar</th>
          </tr>
        </thead>
        <tbody>
          {menuObj &&
            items.map((item) => (
              <tr key={item.id}>
                <td>{item.date.toDate().toLocaleString()}</td>
                <td>
                  {item.cart.map((x) => (
                    <p>
                      {" - " +
                        menuObj[x.meal].name +
                        x.checkedOptions?.map((y) => " +" + y.name)}
                    </p>
                  ))}
                </td>
                <td>{item.phone}</td>
                <td>{calculateToplam(item)}</td>
              </tr>
            ))}
          <tr style={{ fontWeight: "bold" }}>
            <td>Toplam Tutar</td>
            <td></td>
            <td></td>
            <td>{calculateTableTotal()}</td>
          </tr>
        </tbody>
      </Table>
      <Pagination>
        <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
        <Pagination.Prev
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        />
        {[...Array(pageCount)].map((_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === page}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => setPage(page + 1)}
          disabled={page === pageCount}
        />
        <Pagination.Last
          onClick={() => setPage(pageCount)}
          disabled={page === pageCount}
        />
      </Pagination>
    </div>
  );
}

export default OrdersTable;
