import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase, { firestore, auth } from "../../utils/firebase";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/masalar.css";
import { v4 as uuid } from "uuid";
import { AiFillPrinter } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { BiNotepad } from "react-icons/bi";
import { FaExchangeAlt, FaPowerOff } from "react-icons/fa";
import { AdminContext } from "../../layouts/Admin";
import Print from "../../pages/Print";
import { useReactToPrint } from "react-to-print";
const qz = require("qz-tray");
import ReactDOMServer from "react-dom/server";
const New_tables = () => {
  const [selected, select] = useState();
  const [order, setOrder] = useState();
  const history = useHistory();
  const cafe = localStorage.getItem("name");
  const info = JSON.parse(localStorage.getItem("info"));
  const [total, setTotal] = useState(0);
  const [odeme, setOdeme] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [move, setMove] = useState(false);
  const [t1, setT1] = useState(null);
  const [t2, setT2] = useState(null);
  const [pos, setPos] = useState(false);
  const [tutar, setTutar] = useState(0);
  const [posSelected, posSelect] = useState(null);
  const note = useRef();
  const [cart, setCart] = useState([]);
  const { orders, tables, menu, menuObj, tablesObj, isMainPanel } =
    useContext(AdminContext);
  const [discounts, setDiscounts] = useState([]);
  const priceRef = useRef();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState("ALL");
  const [filteredTable, setFilteredTable] = useState(tables);

  useEffect(() => {
    firestore
      .collection(`Restaurants/${cafe}/info`)
      .doc("info")
      .get()
      .then((doc) => {
        setCategories(doc.data().tableCategories);
      });
  }, []);

  useEffect(() => {
    if (category != null) {
      if (category == "ALL") setFilteredTable(tables);
      else setFilteredTable(tables.filter((x) => x[1].category == category));
    }
  }, [category]);
  // useEffect(() => {
  //   let ref = firestore.collection("Restaurants").doc("reddawnburger");

  //   ref.get().then((doc) => {
  //     var extraOptions = [
  //       {
  //         sortOrder: 0,
  //         title: "Patates Tercihi",
  //         Options: [
  //           {
  //             name: "Baharatlı",
  //             sortOrder: 1,
  //             id: uuid(),
  //             price: 0,
  //           },
  //           {
  //             name: "Baharatsız",
  //             sortOrder: 2,
  //             id: uuid(),
  //             price: 0,
  //           },
  //         ],
  //       },
  //     ];
  //     var menu = doc.data().Menu;
  //     var new_menu = menu;
  //     menu.map((c, i) => {
  //       if (c.category == "Sandviçler" || c.category == "Burgerler") {
  //         c.items.map((item, j) => {
  //           new_menu[i].items[j].extraOptions = extraOptions;
  //         });
  //       }
  //     });
  //     ref.update({ Menu: new_menu });
  //   });
  // }, []);

  useEffect(() => {
    if (!selected) {
      setOrder(null);
      return;
    }
    if (orders && selected) {
      setOrder(orders.filter((o) => o.table == selected.id));
      setDiscounts(
        orders
          .filter((o) => o.table == selected.id)
          .map((or) => {
            if (or.discountAmount && parseFloat(or.discountAmount) > 0)
              return {
                id: or.id,
                discount: or.discountAmount,
                phone: or.phone,
                discountUsed: or.discountUsed || false,
              };
            else return null;
          })
          .filter((x) => x)
      );
    }
  }, [selected, orders]);

  const check = (type) => {
    let newOrders = orders;
    newOrders.forEach((o, i, newOrders) => {
      if (o.table == selected.id) {
        newOrders[i][1].status = 3;
      }
    });
    firestore
      .collection(`Restaurants/${cafe}/orders`)
      .doc("order")
      .update(Object.fromEntries(newOrders))
      .then(() => {
        /*let new_tables = tables;
                new_tables.forEach((table, i, new_tables) => {
                  if (table[1].id == selected.id) {
                    new_tables[i][1].status = false;
                  }
                });

                firestore
                  .collection(`Restaurants/${cafe}/info`)
                  .doc("tables")
                  .update(Object.fromEntries(new_tables))
                  .then(() => {
                    select();
                  });*/
      });

    let date = new Date().toLocaleDateString();
    let ref = firestore
      .collection(`Restaurants/${cafe}/admin/orders/${date}`)
      .doc("info");

    ref.get().then((doc) => {
      let data;
      let items = orders
        .filter((o) => o.table == selected.id)
        .map((o) => o.cart);

      let toplamPuan;

      if (info?.hasUserSystem) {
        if (orders && info?.hasUserSystem) {
          toplamPuan =
            orders
              .filter((o) => (o.table = selected.id))
              .map((o) => parseFloat(o.discountAmount))[0] || 0;
        }
      }

      data = doc.data();
      let total = parseFloat(calculateTotal());
      if (type == 0) data.nakit += parseFloat(total);
      else data.kredi += parseFloat(total);
      data.sayı += 1;
      if (info?.hasUserSystem) data.kullanılanPuan += parseFloat(toplamPuan);

      items.map((cart) => {
        cart.map((item) => {
          if (data.sales[item.meal.name])
            data.sales[item.meal.name] += item.quantity;
          else data.sales[item.meal.name] = item.quantity;
        });
      });

      ref.set(data);
    });
  };

  const makePayment = () => {
    let ref = firestore
      .collection(`Restaurants/${cafe}/tables`)
      .doc(order[0].table);
    let paymentRef = firestore.collection(`Restaurants/${cafe}/payments`);
    let ordersRef = firestore.collection(`Restaurants/${cafe}/orders`);
    let usersRef = firestore.collection(`Restaurants/${cafe}/users`);
    let salesRef = firestore
      .collection(`Restaurants/${cafe}/sales`)
      .doc(new Date().toLocaleDateString());

    ref
      .get()
      .then((doc) => {
        let total = doc.data().total;
        let paid = doc.data().paid || 0.0;
        let cc = doc.data().cc || 0.0;
        let cash = doc.data().cash || 0.0;
        let mealCard = doc.data().mealCard ?? 0.0;
        let discountAmount = doc.data().discountAmount || 0.0;
        return { total, paid, cc, cash, discountAmount, mealCard };
      })
      .then(({ total, paid, cc, cash, discountAmount, mealCard }) => {
        pay.map((p) => {
          if (p.type == "Nakit") cash += parseFloat(p.amount);
          else if (p.type == "Kredi") cc += parseFloat(p.amount);
          else if (
            p.type == "Sodexo" ||
            p.type == "Multinet" ||
            p.type == "Edenred" ||
            p.type == "Metropol"
          ) {
            mealCard += parseFloat(p.amount);
          } else if (p.type == "İndirim") {
            let paymentId = uuid();
            firestore
              .collection(`Restaurants/${cafe}/UsedDiscounts`)
              .doc(paymentId)
              .set({
                id: paymentId,
                order: p.id,
                user: discounts.filter((d) => d.id == p.id)[0].phone,
                time: new Date().toISOString(),
                cafe: cafe,
                table: order[0].table,
                amount: p.amount,
              });

            let user = discounts.filter((d) => d.id == p.id)[0].phone;
            // usersRef.doc(user).update({
            //   discount: firebase.firestore.FieldValue.increment(
            //     parseFloat(p.amount) * -1
            //   ),
            // });
            ordersRef.doc(p.id).set({ discountUsed: true }, { merge: true });
          }

          paid += parseFloat(p.amount);

          let id = uuid();
          let time = new Date();
          paymentRef.doc(id).set({
            id,
            orderId: order[0].id,
            date:
              time.getHours() +
              ":" +
              time.getMinutes() +
              ":" +
              time.getSeconds(),
            amount: parseFloat(p.amount),
            type: p.type,
          });
        });
        let newOrders = orders;

        Object.keys(selectedItems).forEach((element) => {
          let sel = selectedItems[element];
          if (sel) {
            newOrders.map((item, i, newOrders) => {
              if (item.id && pay.filter((p) => p.id == item.id).length > 0) {
                newOrders[i].discountUsed = true;
              }
              if (item.id === sel.orderId) {
                item.cart.map((cart, j) => {
                  if (cart.id == element) {
                    newOrders[i].cart[j].quantity -= sel.quantity;
                    if (newOrders[i].cart[j].paid)
                      newOrders[i].cart[j].paid += sel.quantity;
                    else newOrders[i].cart[j].paid = sel.quantity;
                    newOrders[i].total -=
                      sel.quantity *
                      (parseFloat(menuObj[sel.mealId].price) +
                        parseFloat(sel.optPrice));
                  }
                });
                if (
                  paid == total ||
                  item.cart.filter((x) => x.quantity > 0) == 0
                ) {
                  newOrders[i].status = 1;
                }
              }
            });
          }
        });

        salesRef.set(
          {
            nakit: firebase.firestore.FieldValue.increment(cash),
            kredi: firebase.firestore.FieldValue.increment(cc),
            mealCard: firebase.firestore.FieldValue.increment(mealCard),
            discountAmount:
              firebase.firestore.FieldValue.increment(discountAmount),
          },
          { merge: true }
        );

        newOrders?.map((or) => {
          firestore.collection(`Restaurants/${cafe}/orders`).doc(or.id).set(or);
        });

        if (
          total == paid ||
          newOrders.filter((o) => o.status == 0).length == 0
        ) {
          ref.set({
            total: 0.0,
            cc: 0.0,
            mealCard: 0.0,
            cash: 0.0,
            paid: 0.0,
            discountAmount: 0.0,
          });
        } else {
          ref.update({
            paid,
            cc,
            cash,
          });
        }

        var date = new Date().toLocaleDateString();
        firestore
          .collection(`Restaurants/${order.cafe}/sales`)
          .doc(date)
          .get()
          .then((doc) => {
            newOrders?.map((_or) => {
              let items = _or.cart.map((item) => item);
              items.map((el) => {
                if (data.sales[el.meal]) data.sales[el.meal] += el.quantity;
                else data.sales[el.meal] = el.quantity;
              });
            });
            firestore
              .collection(`Restaurants/${order.cafe}/sales`)
              .doc(date)
              .set(data);
          });
      });
  };
  const makePayment1 = () => {
    let newOrders = order;
    Object.keys(selectedItems).forEach((element) => {
      let sel = selectedItems[element];
      if (sel) {
        newOrders.map((item, i, newOrders) => {
          if (item.id === sel.orderId) {
            let odeme = item.cart["odeme"] || {};
            let odemeMiktari = newOrders[i].odemeMiktari || 0;
            if (pay.length == 1) {
              if (pay[0].type == "Nakit")
                odeme.nakit = parseFloat(pay[0].amount);
              else odeme.kredi = parseFloat(pay[0].amount);
              odemeMiktari = parseFloat(pay[0].amount);
              newOrders[i].odemeMiktari = odemeMiktari;
            } else {
              pay?.map((p) => {
                if (p.type == "Nakit") {
                  odeme.nakit
                    ? (odeme.nakit += parseFloat(p.amount))
                    : (odeme.nakit = parseFloat(p.amount));
                } else
                  odeme.kredi
                    ? (odeme.kredi += parseFloat(p.amount))
                    : (odeme.kredi = parseFloat(p.amount));
                odemeMiktari += parseFloat(p.amount);
                newOrders[i].odemeMiktari = odemeMiktari;
              });
            }

            newOrders[i].odeme = odeme;
            item.cart.map((cart, j) => {
              if (cart.id == element) {
                newOrders[i].cart[j].quantity -= sel.quantity;
              }
            });

            if (newOrders[i].odemeMiktari == newOrders[i].total) {
              newOrders[i].status = 1;
            }
          }
        });
      }
    });
    setSelectedItems([]);
    setOpenMenu();
    newOrders?.map((or) => {
      firestore.collection(`Restaurants/${cafe}/orders`).doc(or.id).set(or);
    });

    let date = new Date().toLocaleDateString();
    let ref = firestore
      .collection(`Restaurants/${cafe}/admin/orders/${date}`)
      .doc("info");

    ref.get().then((doc) => {
      const data = doc.data() || {
        sales: {},
        nakit: 0.0,
        kredi: 0.0,
        sayı: 0,
        kullanılanPuan: 0,
      };
      // let items = orders
      //   .filter((o) => o.table == selected.id)
      //   .map((o) => o.cart);

      // let toplamPuan;

      // if (info?.hasUserSystem) {
      //   if (orders && info?.hasUserSystem) {
      //     toplamPuan =
      //       orders
      //         .filter((o) => (o.table = selected.id))
      //         .map((o) => parseFloat(o.discountAmount))[0] || 0;
      //   }
      // }
      pay?.map((p) => {
        if (p.type == "Nakit") data.nakit += parseFloat(p.amount);
        else data.kredi += parseFloat(p.amount);
      });

      // if (info?.hasUserSystem) data.kullanılanPuan += toplamPuan;

      Object.keys(selectedItems).map((el) => {
        let element = selectedItems[el];
        let name = element.name;
        if (data.sales[name]) data.sales[name] += element.quantity;
        else data.sales[name] = element.quantity;
      });

      ref.set(data);
      toast.success("Ödeme Alındı", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setBlurScreen(false);
    });
  };
  const selectAll = () => {
    let items = {};
    order.map((o) => {
      o.cart.map((cart) => {
        if (cart.quantity > 0)
          items[cart.id] = {
            orderId: o.id,
            quantity: cart.quantity,
            mealId: cart.meal,
            optPrice: cart.optPrice,
          };
      });
    });
    setSelectedItems(items);
  };
  const onayla = () => {
    setMove(false);
    let newOrders = orders;
    /*if (orders.filter((o) => o.table == selected?.id).length < 1) {
      setT1();
      setT2();
      return;
    }*/
    orders.forEach((order, i, newOrders) => {
      if (order[1].table == t1.id) newOrders[i][1].table = t2.id;
    });
    let new_tables = tables;
    new_tables.forEach((table, i, new_tables) => {
      if (table[1].id == t1.id) {
        new_tables[i][1].status = false;
      }
      if (table[1].id == t2.id) {
        new_tables[i][1].status = true;
      }
    });
    firestore
      .collection(`Restaurants/${cafe}/orders`)
      .doc("order")
      .update(Object.fromEntries(newOrders))
      .then(() => {
        firestore
          .collection(`Restaurants/${cafe}/info`)
          .doc("tables")
          .set(Object.fromEntries(new_tables))
          .then(() => {
            setT1();
            setT2();
            toast.success("Masa Taşındı", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      });
  };

  const sepeteEkle = (item) => {
    let i = 0;
    let id = uuid();
    if (cart?.length > 0) {
      let new_items = [...cart];
      let index = new_items.findIndex((el) => el == item);
      if (index != -1) {
        i = 1;
        new_items[index].quantity += parseInt(note.current.value) || 1;
        new_items[index].total += parseFloat(item.price) + getOptionsPrice();
        setCart(new_items);
      }
      if (i == 0)
        setCart([
          ...cart,
          {
            id,
            quantity: parseInt(note.current.value) || 1,
            meal: item.id,
            mealNote: "",
            checkedOptions,
            removedOptions,
            optPrice: getOptionsPrice(),
            portion: portion || null,
            total: parseFloat(item.price),
          },
        ]);
    } else {
      setCart([
        {
          id,
          quantity: parseInt(note.current.value) || 1,
          checkedOptions,
          removedOptions,
          meal: item.id,
          mealNote: "",
          optPrice: getOptionsPrice(),
          portion: portion || null,
          total: parseFloat(item.price),
        },
      ]);
    }
    note.current.value = "";
    setCheckedOptions([]);
    setRemovedOptions([]);
  };

  const sepettenCikar = (item) => {
    if (cart?.length > 0) {
      let new_items = [...cart];

      if (item.quantity == 1) {
        setCart(cart.filter((c) => c.id != item.id));
      } else {
        new_items.forEach((it, i, new_items) => {
          if (item.id == it.id) {
            new_items[i].quantity--;
          }
        });
        setCart(new_items);
      }
    }
  };

  const giveOrder = (e) => {
    if (calculateToplam() == "0 TL") {
      toast.error("Ürün yok.", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    var today = new Date();
    let id = uuid();
    let toAdd = {
      id,
      cart,
      table: selected.id,
      total: calculateToplamSepet(),
      note: note.current.value,
      status: 0,
      noNotification: false,
      date: new Date(),
    };
    firestore.collection(`Restaurants/${cafe}/orders`).doc(id).set(toAdd);

    firestore
      .collection(`Restaurants/${cafe}/tables`)
      .doc(selected.id)
      .set(
        {
          total: firebase.firestore.FieldValue.increment(
            calculateToplamSepet()
          ),
        },
        { merge: true }
      );
    let date = new Date().toLocaleDateString();
    let ref = firestore.collection(`Restaurants/${cafe}/sales`).doc(date);

    ref.get().then((doc) => {
      let data = doc.data() || {
        sales: {},
        sayı: 0,
      };
      let items = cart.map((item) => item);
      data.sayı += 1;
      items.map((el) => {
        if (data.sales[el.meal]) data.sales[el.meal] += el.quantity;
        else data.sales[el.meal] = el.quantity;
      });

      ref.set(data);
    });
    cleanSepet();
  };

  const cleanSepet = () => {
    setCart([]);
  };

  const calculateToplam = () => {
    let total = 0.0;
    cart?.map((c) => {
      total += (parseFloat(c.total) + parseFloat(c.optPrice)) * c.quantity;
    });

    return total.toFixed(2) + " TL";
  };
  const calculateTotal = () => {
    let total = 0.0;
    order?.map((c) => {
      total += parseFloat(c.total);
    });
    return total.toFixed(2);
  };
  const calculateToplamSepet = () => {
    let total = 0.0;
    cart?.map((c) => {
      total += (parseFloat(c.total) + parseFloat(c.optPrice)) * c.quantity;
    });
    return total.toFixed(2);
  };

  const [payment, setPayment] = useState("");

  const getDiscountAmounts = () => {
    let total = 0.0;
    order?.map((o) => {
      total += parseFloat(o.discountAmount);
    });
    return total;
  };

  const deleteOrder = (id) => {
    confirmAlert({
      title: "Hesabı Kes",
      message: "Sipariş Silinecek Onaylıyor Musunuz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            let sel = orders?.filter((o) => o[0] == id)[0];
            sel[1]["status"] = 4;
            firestore
              .collection(`Restaurants/${cafe}/orders`)
              .doc("order")
              .update({ [sel[0]]: sel[1] })
              .then(() => {
                if (orders.filter((o) => o[0] == selected.id)) {
                  let new_tables = tables;
                  new_tables.forEach((table, i, new_tables) => {
                    if (table[1].id == selected.id) {
                      new_tables[i][1].status = false;
                    }
                  });

                  firestore
                    .collection(`Restaurants/${cafe}/info`)
                    .doc("tables")
                    .update(Object.fromEntries(new_tables))
                    .then(() => {
                      select();
                    });
                }
              });
          },
        },
        {
          label: "Hayır",
        },
      ],
    });
  };

  const [openOptions, setOpenOptions] = useState();
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [removedOptions, setRemovedOptions] = useState([]);
  const [portion, setPortion] = useState();
  const getOptionsPrice = () => {
    let totalOpt = 0.0;
    checkedOptions.map((opt) => {
      totalOpt += parseFloat(opt.price || 0);
    });
    return totalOpt + (parseFloat(portion?.price) || 0);
  };

  const makeDiscount = () => {
    let or = orders.filter((o) => o.table == selected.id);
    or.map((o, i, or) => {
      or[i][1].indirim = discountAmount;
    });
    firestore
      .collection(`Restaurants/${cafe}/orders`)
      .doc("order")
      .update(Object.fromEntries(or));
  };

  const checkMenuItem = (id) => {
    return orders?.filter((o) => o.table == id).length > 0;
  };

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [showItemMenu, setShowItemMenu] = useState(false);
  const [orderToCancel, setOrderToCancel] = useState();
  const goBack = () => {
    history.push("/admin");
  };
  const [blurScreen, setBlurScreen] = useState(false);

  const [openMenu, setOpenMenu] = useState();
  const openCancelOrderMenu = () => {
    setOpenMenu("cancelMenu");
    setBlurScreen(true);
  };
  const openCancelMenu = () => {
    setOpenMenu("cancelOrderMenu");
    setBlurScreen(true);
  };
  const openPaymentMenu = () => {
    setOpenMenu("paymentMenu");
    setBlurScreen(true);
  };
  const openMoveTablesMenu = () => {
    setOpenMenu("moveTablesMenu");
    setBlurScreen(true);
  };
  const openOptionsMenu = () => {
    setOpenMenu("optionsMenu");
    setBlurScreen(true);
  };
  const cancelOrder = (reason) => {
    try {
      let id = uuid();
      firestore
        .collection(`Restaurants/${cafe}/cancels`)
        .doc(id)
        .set({
          id,
          orderId: orderToCancel.id,
          meals: Object.values(orderToCancel.cart).map((item) => ({
            [menuObj[item.meal].name]: item.quantity,
          })),
          reason,
        });

      let salesRef = firestore
        .collection(`Restaurants/${cafe}/sales`)
        .doc(new Date().toLocaleDateString());
      salesRef
        .get()
        .then((doc) => {
          return doc.data();
        })
        .then((data) => {
          data["sayı"] -= 1;
          orderToCancel.cart.map((order) => {
            data.sales[order.meal] -= 1;
          });
          salesRef.update(data);
        });
      let amountToRemove =
        orderToCancel.discountAmount != null
          ? orderToCancel.total - orderToCancel.discountAmount
          : orderToCancel.total;
      if (orderToCancel.phone) {
        firestore
          .collection(`Restaurants/${cafe}/users`)
          .doc(orderToCancel.phone)
          .update({
            discount: firebase.firestore.FieldValue.increment(
              amountToRemove * -0.1
            ),
          });
      }

      let tableRef = firestore
        .collection(`Restaurants/${cafe}/tables`)
        .doc(orderToCancel.table);

      tableRef.get().then((doc) => {
        let data = doc.data();
        data.total -= orderToCancel.total;
        if (data.total == 0) {
          tableRef.set({
            total: 0.0,
            cc: 0.0,
            cash: 0.0,
            paid: 0.0,
            discountAmount: 0.0,
          });
        } else tableRef.update({ total: data.total });
      });

      firestore
        .collection(`Restaurants/${cafe}/orders`)
        .doc(orderToCancel.id)
        .update({ status: 2, iptal: reason });

      setBlurScreen(false);

      setOpenMenu();
      toast.info("Sipariş İptal Edildi.", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSelectedItem(null);
      setShowItemMenu(false);
      setOrderToCancel();
    } catch (error) {
      return;
    }
  };
  const cancelOrders = (reason) => {
    try {
      let id = uuid();
      firestore
        .collection(`Restaurants/${cafe}/cancels`)
        .doc(id)
        .set({
          id,
          orderId: order[0].id,
          meals: Object.values(selectedItems).map((item) => ({
            [menuObj[item.mealId].name]: item.quantity,
          })),
          reason,
        });
      if (Object.keys(selectedItems).length < 1) return;
      let newOrders = order;
      let tableRef = firestore
        .collection(`Restaurants/${cafe}/tables`)
        .doc(order[0].table);

      let salesRef = firestore
        .collection(`Restaurants/${cafe}/sales`)
        .doc(new Date().toLocaleDateString());

      tableRef.get().then((doc) => {
        let total = doc.data().total;
        Object.keys(selectedItems).forEach((element) => {
          let sel = selectedItems[element];
          newOrders.map((item, i, newOrders) => {
            if (item.id === sel.orderId) {
              item.cart.map((cart, j) => {
                if (cart.id == element) {
                  let iptal = cart["iptal"] || {};
                  let iptalMiktari = cart["iptalMiktari"] || 0;
                  iptal[reason]
                    ? (iptal[reason] += sel.quantity)
                    : (iptal[reason] = sel.quantity);
                  iptalMiktari += sel.quantity;
                  newOrders[i].cart[j].iptal = iptal;
                  newOrders[i].cart[j].quantity -= sel.quantity;
                  newOrders[i].cart[j].iptalMiktari = iptalMiktari;
                  newOrders[i].total -= sel.quantity * cart.total;
                  total -= sel.quantity * cart.total;
                  if (total == 0) newOrders[i].status = 1;
                }
              });
            }
          });
          setSelectedItems({ ...selectedItems, [element]: null });
        });

        if (calculateTotal() == 0) {
          tableRef.set({
            total: 0.0,
            cc: 0.0,
            cash: 0.0,
            paid: 0.0,
            discountAmount: 0.0,
          });
        } else tableRef.update({ total });
        newOrders.map((or) => {
          firestore.collection(`Restaurants/${cafe}/orders`).doc(or.id).set(or);
        });
        salesRef
          .get()
          .then((doc) => {
            return doc.data();
          })
          .then((data) => {
            Object.keys(selectedItems).forEach((element) => {
              let sel = selectedItems[element];
              data.sales[sel.mealId] -= parseFloat(sel.quantity);
            });
            salesRef.update(data);
          });
      });

      setBlurScreen(false);

      setOpenMenu();
      toast.info("Ürünler İptal Edildi.", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSelectedItem(null);
      setShowItemMenu(false);
    } catch (error) {
      return;
    }
  };
  const calculateTotalToPayment = () => {
    let total = 0.0;
    Object.keys(selectedItems).map((item) => {
      if (selectedItems[item] != null)
        total +=
          (parseFloat(menuObj[selectedItems[item]?.mealId].price) +
            selectedItems[item]?.optPrice) *
            selectedItems[item]?.quantity || 0.0;
    });
    return total;
  };
  const paymentInput = useRef();
  const [pay, setPay] = useState([]);
  const calculatePay = () => {
    let total = 0.0;
    pay?.map((p) => {
      total += parseFloat(p.amount || 0.0);
    });
    return total;
  };
  useEffect(() => {
    if (!pay) return;
    if (Object.keys(selectedItems).length > 0 && pay) {
      if (calculateTotalToPayment() == calculatePay()) {
        makePayment();
        setPay([]);
        setOpenMenu();
        setBlurScreen(false);
        setSelectedItem();
        setSelectedItems([]);
      }
    }
  }, [pay]);

  /*const moveTable = (id) => {
      let new_orders = order;
      let orderId = uuid();

      new_orders.forEach( (o,i,new_orders) =>  {
        new_orders[i].cart = o.cart.filter(item =>  Object.keys(selectedItems).includes(item.id) );
      } );
      new_orders.map((or) => {
        firestore.collection(`Restaurants/${cafe}/orders`).doc(or.id).set(or);
      });   
      firestore.collection(`Restaurants/${cafe}/orders`).doc(orderId)
      let toAdd = {
        id : orderId ,
        cart,
        table: selected.id,
        total: calculateToplam().split(" ")[0],
        note: note.current.value,
        status: 0,
        noPrint: true,
        day: today.toLocaleDateString("en-US"),
        time:
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds(),
      };
      firestore.collection(`Restaurants/${cafe}/orders`).doc(id).set(toAdd);
  }*/
  const moveTable = (id) => {
    let new_orders = order;
    new_orders.forEach((o, i, new_orders) => {
      new_orders[i].table = id;
    });
    new_orders.map((or) => {
      firestore.collection(`Restaurants/${cafe}/orders`).doc(or.id).set(or);
    });

    setOpenMenu();
    setBlurScreen(false);
  };
  const printRef = useRef();
  const [orderToPrint, setOrderToPrint] = useState(null);
  const printData = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setOrderToPrint(null),
  });

  useEffect(() => {
    if (orderToPrint && isMainPanel) {
      if (info?.adisyon != null) {
        qz.websocket
          .connect({ host: info.host })
          .then(() => {
            var data = ReactDOMServer.renderToStaticMarkup(
              <Print
                ref={printRef}
                order={orderToPrint}
                table={orderToPrint ? tablesObj[orderToPrint[0].table] : ""}
                menuObj={menuObj}
                total={calculateTotal()}
              />
            );
            let config = qz.configs.create(info?.adisyon);
            return qz.print(config, [
              {
                type: "pixel",
                format: "html",
                flavor: "plain",
                data,
              },
            ]);
          })
          .then(() => {
            return qz.websocket.disconnect();
          })
          .catch((err) => {
            console.error(err);
            return qz.websocket.disconnect();
          });
      } else {
        printData();
      }
    }
  }, [orderToPrint]);
  return (
    <>
      <div style={{ display: "none" }}>
        <Print
          ref={printRef}
          order={orderToPrint}
          table={orderToPrint ? tablesObj[orderToPrint[0].table] : ""}
          menuObj={menuObj}
          total={calculateTotal()}
        />
      </div>
      <div>
        <div className="btn btn-dark mt-3" onClick={goBack}>
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            style={{ width: "2vw" }}
          ></i>
        </div>
      </div>
      <div
        className="blurred"
        style={{ display: blurScreen ? "block" : "none" }}
      ></div>
      {openMenu == "cancelMenu" && (
        <div className="cancel-menu">
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrders("Yanlış Sipariş")}
          >
            Yanlış Sipariş
          </div>
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrders("Müşteri İptali")}
          >
            Müşteri İptali
          </div>
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrders("Beğenilmedi")}
          >
            Beğenilmedi
          </div>
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrders("Zayi")}
          >
            Zayi
          </div>
          <div
            className="cancel-menu-item red"
            onClick={() => {
              setBlurScreen(false);
              setOpenMenu();
            }}
          >
            İptal
          </div>
        </div>
      )}
      {openMenu == "cancelOrderMenu" && (
        <div className="cancel-menu">
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrder("Yanlış Sipariş")}
          >
            Yanlış Sipariş
          </div>
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrder("Müşteri İptali")}
          >
            Müşteri İptali
          </div>
          <div
            className="cancel-menu-item"
            onClick={() => cancelOrder("Beğenilmedi")}
          >
            Beğenilmedi
          </div>
          <div className="cancel-menu-item" onClick={() => cancelOrder("Zayi")}>
            Zayi
          </div>
          <div
            className="cancel-menu-item red"
            onClick={() => {
              setBlurScreen(false);
              setOpenMenu();
            }}
          >
            İptal
          </div>
        </div>
      )}

      {openMenu == "paymentMenu" && (
        <div className="payment-menu">
          {discounts.length > 0 && (
            <div className="discount-container">
              <b>Kullanılan İndirimler</b>
              <hr />
              {discounts.map((d) => (
                <div
                  style={{
                    textDecoration: d.discountUsed ? "line-through" : "",
                    cursor: d.discountUsed ? "none" : "",
                  }}
                  onClick={() => {
                    if (d.discountUsed) return;
                    setPay([
                      ...pay,
                      {
                        type: "İndirim",
                        amount: d.discount,
                        id: d.id,
                      },
                    ]);
                  }}
                >
                  <p> {d.discount} &#x20BA;</p>
                  <p>{d.phone}</p>
                </div>
              ))}
            </div>
          )}
          <div className="payment-container">
            <div className="row" style={{ height: "100%", padding: 5 }}>
              <div
                className="col-4 "
                style={{ height: "100%", borderRight: "1px solid black" }}
              >
                <div
                  className="btn btn-outline-danger"
                  style={{ fontWeight: "bold" }}
                  onClick={() => {
                    setBlurScreen(false);
                    setOpenMenu();
                    setSelectedItems([]);
                    paymentInput.current.value = "";
                    setPay([]);
                  }}
                >
                  X
                </div>
                <p className="mt-1">
                  Tutar:{" "}
                  <span style={{ float: "right", fontWeight: "bold" }}>
                    {calculateTotalToPayment()}TL
                  </span>
                </p>

                <p className="mt-3">
                  <span>
                    Giriş:
                    <input
                      ref={paymentInput}
                      type="text"
                      className="payment-menu-input"
                      placeholder="0"
                    />
                  </span>
                </p>
                {pay?.map((p) => (
                  <p style={{ color: "gray", fontSize: "0.8vw" }}>
                    {p.type}{" "}
                    <span style={{ float: "right", fontWeight: "bold" }}>
                      {p.amount}
                    </span>
                  </p>
                ))}

                {pay && (
                  <p>
                    Kalan:
                    <span style={{ float: "right", fontWeight: "bold" }}>
                      {calculateTotalToPayment() - calculatePay()}
                    </span>
                  </p>
                )}
              </div>

              <div className="col-6 payment-numbers mt-5">
                <div onClick={() => (paymentInput.current.value += "1")}>1</div>
                <div onClick={() => (paymentInput.current.value += "2")}>2</div>
                <div onClick={() => (paymentInput.current.value += "3")}>3</div>
                <div
                  onClick={() =>
                    (paymentInput.current.value =
                      calculateTotalToPayment() - calculatePay())
                  }
                >
                  Tümü
                </div>
                <div onClick={() => (paymentInput.current.value += "4")}>4</div>

                <div onClick={() => (paymentInput.current.value += "5")}>5</div>
                <div onClick={() => (paymentInput.current.value += "6")}>6</div>

                <div
                  onClick={() =>
                    (paymentInput.current.value = (
                      calculateTotalToPayment() / 2
                    ).toFixed(2))
                  }
                >
                  1/2
                </div>
                <div onClick={() => (paymentInput.current.value += "7")}>7</div>
                <div onClick={() => (paymentInput.current.value += "8")}>8</div>
                <div onClick={() => (paymentInput.current.value += "9")}>9</div>

                <div
                  onClick={() =>
                    (paymentInput.current.value = (
                      calculateTotalToPayment() / 3
                    ).toFixed(2))
                  }
                >
                  1/3
                </div>
                <div onClick={() => (paymentInput.current.value += "0")}>0</div>

                <div
                  onClick={() =>
                    (paymentInput.current.value =
                      paymentInput.current.value.slice(0, -1))
                  }
                >
                  {"<"}
                </div>
                <div onClick={() => (paymentInput.current.value = "")}>
                  {"C"}
                </div>
                <div
                  onClick={() =>
                    (paymentInput.current.value = (
                      calculateTotalToPayment() / 4
                    ).toFixed(2))
                  }
                >
                  1/4
                </div>
              </div>

              <div className="col-2 payment-methods">
                {info.paymentMethods &&
                  info.paymentMethods.map((method) => (
                    <div
                      className="btn btn-outline-secondary mx-auto mt-2 w-75"
                      style={{
                        height: "15%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        if (
                          parseFloat(paymentInput.current.value) +
                            (parseFloat(calculatePay()) || 0.0) >
                          calculateTotalToPayment()
                        ) {
                          alert("Hatalı Giriş");
                          paymentInput.current.value = "";
                          return;
                        }
                        if (paymentInput.current.value === "") {
                          paymentInput.current.value =
                            calculateTotalToPayment() - calculatePay();
                        }
                        setPay([
                          ...pay,
                          {
                            type: method,
                            amount: paymentInput.current.value,
                          },
                        ]);
                        paymentInput.current.value = "";
                      }}
                    >
                      {method}
                    </div>
                  ))}
                {!info.paymentMethods && (
                  <>
                    {" "}
                    <div
                      className="btn btn-dark w-100  h-25 d-flex"
                      style={{
                        marginTop: "80%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        borderRadius: "10%",
                      }}
                      onClick={() => {
                        if (
                          parseFloat(paymentInput.current.value) +
                            (parseFloat(calculatePay()) || 0.0) >
                          calculateTotalToPayment()
                        ) {
                          alert("Hatalı Giriş");
                          paymentInput.current.value = "";
                          return;
                        }
                        if (paymentInput.current.value === "") {
                          paymentInput.current.value =
                            calculateTotalToPayment() - calculatePay();
                        }
                        setPay([
                          ...pay,
                          { type: "Nakit", amount: paymentInput.current.value },
                        ]);
                        paymentInput.current.value = "";
                      }}
                    >
                      Nakit
                    </div>
                    <div
                      className="btn btn-danger mt-2 w-100 h-25"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                      onClick={() => {
                        if (
                          parseFloat(paymentInput.current.value) +
                            (parseFloat(calculatePay()) || 0.0) >
                          calculateTotalToPayment()
                        ) {
                          alert("Hatalı Giriş");
                          paymentInput.current.value = "";
                          return;
                        }
                        if (paymentInput.current.value === "") {
                          paymentInput.current.value =
                            calculateTotalToPayment() - calculatePay();
                        }
                        setPay([
                          ...pay,
                          {
                            type: "Kredi",
                            amount: paymentInput.current.value,
                          },
                        ]);
                        paymentInput.current.value = "";
                      }}
                    >
                      Kredi Kartı
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openMenu == "moveTablesMenu" && (
        <div className="move-tables-menu">
          <div className="move-tables-container">
            <span>
              <div
                className="btn btn-danger"
                style={{ cursor: "pointer", width: "10%" }}
                onClick={() => {
                  setBlurScreen(false);
                  setOpenMenu();
                }}
              >
                x
              </div>
            </span>
            <div className="row mt-2" style={{ height: "100%", padding: 5 }}>
              <div className="move-tables">
                {tables &&
                  tables.map((table) => {
                    return (
                      <div onClick={() => moveTable(table[0])}>
                        {table[1].name}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
      {openMenu == "optionsMenu" && (
        <div>
          {openOptions?.showInMenu ? (
            <div className="meal-options-menu">
              <div
                className="meal-options-container text-center"
                style={{ fontSize: "20px" }}
              >
                Tutar:
                <input
                  style={{ height: "5vh" }}
                  type="number"
                  ref={priceRef}
                  onChange={() => {
                    setCheckedOptions([
                      {
                        id: "0",
                        name: "",
                        price: priceRef.current.value,
                        sortOrder: 0,
                      },
                    ]);
                  }}
                />
                <div
                  className="btn btn-danger"
                  style={{ marginLeft: 100 }}
                  onClick={() => {
                    setOpenOptions(null);
                    setOpenMenu();
                    setCheckedOptions([]);
                    setBlurScreen(false);
                  }}
                >
                  Kapat
                </div>
                <div
                  className=" btn btn-secondary"
                  onClick={() => {
                    sepeteEkle(openOptions);
                    setOpenMenu();
                    setBlurScreen(false);
                    setOpenOptions(null);
                  }}
                >
                  Onayla
                </div>
              </div>
            </div>
          ) : (
            <div className="meal-options-menu">
              <div className="meal-options-container">
                <div className="row">
                  <div
                    className={
                      openOptions?.removeOptions &&
                      openOptions?.removeOptions.length > 0
                        ? "col-5"
                        : "col-10"
                    }
                  >
                    {openOptions?.addOptions?.map((opt) => (
                      <div
                        className="card"
                        onClick={() => {
                          if (checkedOptions.includes(opt)) {
                            setCheckedOptions(
                              checkedOptions.filter((op) => op != opt)
                            );
                          } else {
                            setCheckedOptions([...checkedOptions, opt]);
                          }
                        }}
                        style={{
                          border: checkedOptions.includes(opt)
                            ? "0.5px solid red"
                            : "",
                        }}
                      >
                        <h5>{opt.name}</h5>
                        <h5>{opt.price} TL</h5>
                      </div>
                    ))}
                  </div>
                  {openOptions?.removeOptions &&
                    openOptions.removeOptions.length > 0 && (
                      <div className="col-6 card">
                        {openOptions?.removeOptions?.map((opt) => (
                          <div
                            onClick={() => {
                              if (removedOptions.includes(opt)) {
                                setRemovedOptions(
                                  removedOptions.filter((op) => op != opt)
                                );
                              } else {
                                setRemovedOptions([...removedOptions, opt]);
                              }
                            }}
                            style={{
                              border: removedOptions.includes(opt)
                                ? "0.5px solid red"
                                : "",
                            }}
                          >
                            <h5>{opt.name}</h5>
                          </div>
                        ))}
                      </div>
                    )}
                  <div className="col-1">
                    {openOptions?.portions?.map((opt) => (
                      <div
                        className="card"
                        onClick={() => {
                          if (checkedOptions.includes(opt)) {
                            setCheckedOptions(
                              checkedOptions.filter((op) => op != opt)
                            );
                          } else {
                            setCheckedOptions([...checkedOptions, opt]);
                          }
                        }}
                        style={{
                          border: checkedOptions.includes(opt)
                            ? "0.5px solid red"
                            : "",
                        }}
                      >
                        <h5 className="card-title">{opt.name}</h5>
                        <h5 className="card-text">{opt.price} TL</h5>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-5 text-center" style={{ height: 60 }}>
                  <div
                    className="btn btn-danger w-25 h-100"
                    style={{ fontSize: "1.3vw" }}
                    onClick={() => {
                      setOpenOptions(null);
                      setOpenMenu();
                      setCheckedOptions([]);
                      setBlurScreen(false);
                    }}
                  >
                    Kapat
                  </div>
                  <div
                    className="btn btn-secondary w-25 h-100"
                    style={{ fontSize: "1.3vw" }}
                    onClick={() => {
                      sepeteEkle(openOptions);
                      setOpenMenu();
                      setBlurScreen(false);
                      setOpenOptions(null);
                    }}
                  >
                    Onayla
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="text-center">
        <div
          className={
            "btn btn-lg mx-3 " +
            (category == "ALL" ? "btn-dark" : "btn-outline-dark")
          }
          onClick={() => {
            setCategory("ALL");
          }}
        >
          Tümü
        </div>
        {categories?.map((b) => {
          return (
            <div
              className={
                "btn btn-lg mx-1 " +
                (category == b ? "btn-secondary" : "btn-outline-secondary")
              }
              onClick={() => {
                setCategory(b);
              }}
            >
              {b}
            </div>
          );
        })}
      </div>

      <div style={{ height: "100vh", overflowX: "hidden" }}>
        <div className="row mt-4">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {filteredTable?.map((m) => {
              return (
                <div
                  style={{
                    width: 200,
                    height: 150,
                    margin: 5,
                    border: "1px solid gray ",
                    borderRadius: "5px",
                    backgroundColor: checkMenuItem(m[1].id)
                      ? "#BC125A"
                      : "#15A04B",
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (!move) {
                      if (selected == m[1]) select(null);
                      else select(m[1]);
                    } else {
                      if (!t1 && !t2) {
                        setT1(m[1]);
                      } else if (t1 && !t2) {
                        setT2(m[1]);
                      }
                    }
                  }}
                >
                  <p style={{ margin: 0 }}>{m[1].name}</p>
                </div>
              );
            })}
          </div>
        </div>

        {selected && (
          <div className="pos">
            <div className="content">
              <div className="row">
                <div className="col-1 pos-menu" style={{ cursor: "pointer" }}>
                  {showItemMenu ? (
                    <>
                      <div
                        className="menu-item"
                        onClick={() => {
                          setShowItemMenu(false);
                          setSelectedItem(null);
                          setSelectedItems([]);
                        }}
                        style={{ fontSize: "1vw" }}
                      >
                        Kapat
                      </div>

                      <div
                        className="menu-item"
                        onClick={() => {
                          if (
                            selectedItems[selectedItem].quantity <
                            selectedItems[selectedItem].maxQuantity
                          )
                            setSelectedItems((prev) => ({
                              ...selectedItems,
                              [selectedItem]: {
                                ...prev[selectedItem],
                                quantity:
                                  (prev[selectedItem]?.quantity || 0) + 1,
                              },
                            }));
                        }}
                        style={{
                          pointerEvents: selectedItems[selectedItem]
                            ? ""
                            : "none",
                          fontSize: "1.5vw",
                        }}
                      >
                        +
                      </div>
                      <div
                        className="menu-item"
                        style={{
                          pointerEvents: selectedItems[selectedItem]
                            ? ""
                            : "none",
                          fontSize: "1.5vw",
                        }}
                        onClick={() => {
                          if (!selectedItems[selectedItem]) return;
                          if (selectedItems[selectedItem]?.quantity == 1) {
                            setSelectedItems({
                              ...selectedItems,
                              [selectedItem]: null,
                            });
                            setSelectedItem(null);
                            return;
                          }
                          setSelectedItems((prev) => ({
                            ...selectedItems,
                            [selectedItem]: {
                              ...prev[selectedItem],
                              quantity: prev[selectedItem].quantity - 1,
                            },
                          }));
                        }}
                      >
                        -
                      </div>

                      <div
                        className="menu-item"
                        onClick={() => {
                          if (Object.keys(selectedItems).length > 0)
                            openPaymentMenu();
                          else {
                            selectAll();
                            openPaymentMenu();
                          }
                        }}
                      >
                        <MdPayment />
                      </div>
                      <div
                        className="menu-item mi-r"
                        onClick={() => openCancelOrderMenu()}
                      >
                        <ImCancelCircle />
                      </div>

                      {isMainPanel && (
                        <div
                          className="menu-item "
                          onClick={() => setOrderToPrint(order)}
                        >
                          <AiFillPrinter />
                        </div>
                      )}

                      <div
                        className="menu-item btn btn-danger"
                        onClick={() => {
                          if (cart?.length > 0) giveOrder();
                          select(null);
                          setSelectedItems([]);
                          setSelectedItem(null);
                          setOpenOptions(false);
                          setCheckedOptions([]);
                        }}
                      >
                        <FaPowerOff />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="menu-item mi-r">
                        <ImCancelCircle />
                      </div>
                      <div
                        className="menu-item"
                        onClick={() => {
                          openMoveTablesMenu();
                        }}
                      >
                        <FaExchangeAlt />
                      </div>
                      <div
                        className="menu-item"
                        onClick={() => {
                          selectAll();
                          openPaymentMenu();
                        }}
                      >
                        <MdPayment />
                      </div>
                      <div className="menu-item">
                        <BiNotepad />
                      </div>

                      {isMainPanel && (
                        <div
                          className="menu-item "
                          onClick={() => setOrderToPrint(order)}
                        >
                          <AiFillPrinter />
                        </div>
                      )}
                      <div
                        className="menu-item btn btn-danger"
                        onClick={() => {
                          if (cart?.length > 0) giveOrder();
                          select(null);
                          setSelectedItems([]);
                          setSelectedItem(null);
                          setOpenOptions(false);
                          setCheckedOptions([]);
                        }}
                      >
                        <FaPowerOff />
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="col-3"
                  style={{
                    position: "relative",
                  }}
                >
                  {/*<div className="not-ekle">
                  <p>Not Ekle</p>""
                  <div className="input-group" id="meal-note">
                    <textarea
                      className="form-control"
                      aria-label="With textarea"
                      ref={note}
                      style={{
                        borderRadius: 10,
                        height: "3%",
                      }}
                    ></textarea>
                  </div>
                    </div>*/}

                  <p
                    style={{ fontWeight: "bold" }}
                    className="text-center mt-3"
                  >
                    Adisyon
                  </p>
                  <ul className="list-group adisyon">
                    {cart &&
                      cart?.length > 0 &&
                      cart?.map((s) => (
                        <li
                          className="list-group-item"
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            sepettenCikar(s);
                          }}
                        >
                          <div className="row">
                            <div className="col-10">
                              <span>
                                {s.quantity} x
                                {(s.portion != null ? s.portion.name : "") +
                                  " " +
                                  menuObj[s.meal].name}
                              </span>
                              <p style={{ color: "#727272" }}>
                                {s.checkedOptions &&
                                  s.checkedOptions
                                    .map((opt) => opt.name)
                                    .join(",")}
                              </p>
                              {s.removedOptions.length > 0 && (
                                <p style={{ color: "#727272" }}>
                                  Çıkarılacaklar:
                                  {s.removedOptions
                                    .map((opt) => opt.name)
                                    .join(",")}
                                </p>
                              )}
                            </div>
                            <div className="col-2">
                              <span style={{ position: "absolute", right: 10 }}>
                                {(s.total + s.optPrice) * s.quantity} TL
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    {cart.length > 0 && (
                      <p
                        style={{
                          textAlign: "right",
                          fontSize: "1.5vw",
                          fontWeight: "bold",
                        }}
                      >
                        Sepet Toplam: {calculateToplamSepet()} &#x20BA;
                      </p>
                    )}

                    {order &&
                      order
                        ?.sort((a, b) => {
                          if (a.date >= b.date) return -1;
                          else return 1;
                        })
                        .map((o) => (
                          <div>
                            <span
                              style={{
                                textAlign: "left",
                                fontSize: "0.8vw",
                                marginTop: "1%",
                              }}
                            >
                              {o.date.toDate().toLocaleTimeString()}
                            </span>
                            <span
                              style={{
                                float: "right",
                                fontWeight: "bold",
                                fontSize: "0.8vw",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOrderToCancel(o);
                                openCancelMenu();
                              }}
                            >
                              Sipariş İptal
                            </span>
                            {o.cart.map((cart) => (
                              <>
                                <li
                                  className="list-group-item"
                                  style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    backgroundColor: selectedItems[cart.id]
                                      ? "#eee"
                                      : "",
                                    border:
                                      cart.id == selectedItem
                                        ? "1px solid black"
                                        : "",
                                  }}
                                  onClick={() => {
                                    if (cart.quantity < 1) return;

                                    if (!selectedItems[cart.id]) {
                                      setSelectedItems({
                                        ...selectedItems,
                                        [cart.id]: {
                                          orderId: o.id,
                                          quantity: 1,
                                          maxQuantity: cart.quantity,
                                          mealId: cart.meal,
                                          optPrice: cart.optPrice,
                                        },
                                      });
                                    } else {
                                      if (
                                        Object.keys(selectedItems).length == 1
                                      ) {
                                        setSelectedItems({});
                                        setSelectedItem(null);
                                        setShowItemMenu(false);
                                        return;
                                      }
                                      setSelectedItems({
                                        ...selectedItems,
                                        [cart.id]: null,
                                      });

                                      setSelectedItem(null);
                                      return;
                                    }

                                    setShowItemMenu(true);
                                    setSelectedItem(cart.id);
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{ fontSize: "0.7vw" }}
                                  >
                                    <div className="col-10">
                                      {cart.quantity > 0 ? (
                                        <>
                                          <span>
                                            {cart.quantity} x
                                            {cart.portion ? (
                                              <>
                                                {cart.portion.name}
                                                &nbsp; {menuObj[cart.meal].name}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <>&nbsp;{menuObj[cart.meal].name}</>
                                            <span style={{ fontSize: "1vw" }}>
                                              {selectedItems[cart.id]
                                                ? " (" +
                                                  selectedItems[cart.id]
                                                    .quantity +
                                                  ")"
                                                : null}
                                            </span>
                                          </span>
                                          <p style={{ color: "#727272" }}>
                                            {cart.checkedOptions &&
                                              cart.checkedOptions
                                                .map((opt) => opt.name)
                                                .join(",")}
                                          </p>
                                          {cart.removedOptions.length > 0 && (
                                            <p style={{ color: "#727272" }}>
                                              Çıkarılacaklar:
                                              {cart.removedOptions
                                                .map((opt) => opt.name)
                                                .join(",")}
                                            </p>
                                          )}
                                          {cart.extraOptions && (
                                            <p style={{ color: "#727272" }}>
                                              Patates Seçimi:
                                              {cart.extraOptions.name}
                                            </p>
                                          )}
                                          {cart.mealNote && (
                                            <p style={{ color: "#727272" }}>
                                              Not: {cart.mealNote}
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <span
                                            style={{
                                              color: "#aaa",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {cart.paid} x
                                            {(cart.portion != null ? (
                                              <p>
                                                {cart.portion &&
                                                  cart.portion.name + " "}{" "}
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {menuObj[cart.meal].name}
                                                </span>
                                              </p>
                                            ) : (
                                              " "
                                            )) +
                                              " " +
                                              menuObj[cart.meal].name}
                                            <span style={{ fontSize: "1vw" }}>
                                              {selectedItems[cart.id]
                                                ? " (" +
                                                  selectedItems[cart.id]
                                                    .quantity +
                                                  ")"
                                                : null}
                                            </span>
                                            <p>
                                              {cart.checkedOptions &&
                                                cart.checkedOptions
                                                  .map((opt) => opt.name)
                                                  .join(",")}
                                            </p>
                                          </span>
                                        </>
                                      )}
                                      {cart.iptalMiktari
                                        ? Object.keys(cart.iptal).map((ip) => {
                                            return (
                                              <p style={{ color: "red" }}>
                                                İptal:{" "}
                                                {cart.iptal[ip] +
                                                  " x " +
                                                  menuObj[cart.meal].name +
                                                  " "}
                                                {ip}
                                              </p>
                                            );
                                          })
                                        : null}
                                    </div>
                                    <div className="col-2 mt-3">
                                      <span>
                                        {cart.total + cart.optPrice} TL
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ))}
                            {o.discountAmount &&
                              parseFloat(o.discountAmount) != 0 && (
                                <div
                                  style={{
                                    fontSize: "0.7vw",
                                    fontWeight: "bold",
                                    color: "#909090",
                                  }}
                                >
                                  <span>
                                    Kullanılan Puan, Telefon: {o.phone}
                                  </span>
                                  <span style={{ float: "right" }}>
                                    {" "}
                                    {o.discountAmount}&#x20BA;
                                  </span>
                                </div>
                              )}
                          </div>
                        ))}
                  </ul>
                  {getDiscountAmounts() > 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        bottom: "5%",
                        width: "100%",
                      }}
                      className="mx-auto"
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5vw",
                          textAlign: "left",
                          color: "#dc3545",
                        }}
                      >
                        Kullanılan Puan:{" "}
                        <span>{getDiscountAmounts()} &#x20BA;</span>
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                    }}
                    className="mx-auto"
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "2vw",
                        textAlign: "center",
                      }}
                    >
                      Toplam: <span>{calculateTotal()} &#x20BA;</span>
                    </p>
                  </div>
                </div>
                <div className="col-8">
                  <div className="row" style={{ width: "98%", height: "100%" }}>
                    <div
                      className="col-3 categories mt-5"
                      style={{
                        gridTemplateRows:
                          "repeat(" +
                          (menu.map((m) => m.category).length / 2 + 1) +
                          ",1fr)",
                      }}
                    >
                      {menu &&
                        menu.map((m) => (
                          <div
                            className="category-item"
                            onClick={() => posSelect(m)}
                            style={{ cursor: "pointer" }}
                          >
                            {m.category}
                          </div>
                        ))}
                    </div>

                    <div className="col-9  mt-5 ">
                      <div className="itemler">
                        {posSelected &&
                          posSelected.items
                            .filter((i) => i.active == 1)
                            .map((item) => (
                              <>
                                <div
                                  className="card"
                                  onClick={() => {
                                    if (
                                      item.addOptions?.length > 0 ||
                                      item.removeOptions?.length > 0 ||
                                      item.portions?.length > 0
                                    ) {
                                      setOpenOptions(item);
                                      openOptionsMenu();
                                    } else {
                                      sepeteEkle(item);
                                    }
                                  }}
                                  style={{
                                    border:
                                      openOptions == item
                                        ? "1px solid red"
                                        : "",
                                  }}
                                >
                                  <div
                                    className="card-body"
                                    style={{ fontSize: "1.1vw" }}
                                  >
                                    <h5 className="card-title">{item.name}</h5>
                                    <h5 className="card-text">
                                      {item.price} TL
                                    </h5>
                                  </div>
                                </div>
                              </>
                            ))}
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          position: "absolute",
                          bottom: "5%",
                        }}
                      >
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            aria-label="With textarea"
                            ref={note}
                            placeholder="0"
                            style={{
                              borderRadius: 10,
                              textAlign: "right",
                            }}
                          ></input>

                          <div className="numbers">
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "1")}
                            >
                              1
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "2")}
                            >
                              2
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "3")}
                            >
                              3
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "4")}
                            >
                              4
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "5")}
                            >
                              5
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "6")}
                            >
                              6
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "7")}
                            >
                              7
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "8")}
                            >
                              8
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "9")}
                            >
                              9
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value += "0")}
                            >
                              0
                            </div>
                            <div
                              className="number-item"
                              onClick={() =>
                                (note.current.value = note.current.value.slice(
                                  0,
                                  -1
                                ))
                              }
                            >
                              {" "}
                              {"<"}{" "}
                            </div>
                            <div
                              className="number-item"
                              onClick={() => (note.current.value = "")}
                            >
                              {"C"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default New_tables;
