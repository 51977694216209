import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import "../css/print.css";

const AutoPrint = React.forwardRef((props, ref) => {
  const { order, table, display, menuObj } = props;
  const info = JSON.parse(localStorage.getItem("info"));
  const history = useHistory();

  return (
    order && (
      <div className="ticket" ref={ref}>
        {/* {(order.paket || order.arama) && (
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
          >
            <img
              src={info?.logo}
              style={{ width: "60%", height: "auto" }}
              alt="Logo"
            />
          </div>
        )} */}
        {(order.paket || order.arama) && (
          <p className="centered" style={{ fontSize: 25 }}>
            {info.name}
          </p>
        )}
        <div className="centered">
          {!(order.paket || order.arama) && table?.name}{" "}
          {order.name && (
            <p>
              <span>{order.name}</span>
            </p>
          )}
          {(order.paket || order.arama) && order.phone && (
            <p>
              <span style={{ fontWeight: "bold" }}>Telefon:</span>
              <span>{order.phone}</span>
            </p>
          )}
          {(order.paket || order.arama) && order.address && (
            <p>
              <span style={{ fontWeight: "bold" }}>Adres:</span>
              <span>{order.address}</span>
            </p>
          )}
          {(order.paket || order.arama) && order.paymentMethod && (
            <p>
              <span style={{ fontWeight: "bold" }}>Ödeme Yöntemi:</span>
              <span>{order.paymentMethod}</span>
            </p>
          )}
          <p>
            <span>{"Saat: " + order.date.toDate().toLocaleTimeString()}</span>
            {/* {order.date.toDate().toLocaleDateString()} */}
          </p>
        </div>
        <table>
          <thead>
            <tr>
              <th className="quantity">Adet</th>
              <th className="description">Ürün</th>
              <th className="price">Fiyat</th>
            </tr>
          </thead>
          <tbody>
            {order.cart
              .filter(
                (x) =>
                  !order.paket &&
                  (menuObj[x.meal].noPrint == null ||
                    menuObj[x.meal].noPrint == false)
              )
              .map((cart) => (
                <>
                  <tr style={{ borderBottom: "1px solid gray" }}>
                    <td className="quantity">{cart.quantity}</td>
                    <td className="description">
                      {cart.portion != null && cart.portion ? (
                        <p>{cart.portion.name}</p>
                      ) : (
                        ""
                      )}
                      <p>{menuObj[cart.meal].name}</p>
                      <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                        {cart.checkedOptions?.length > 0 &&
                          cart.checkedOptions?.map((opt) => (
                            <span>{opt.name}</span>
                          ))}
                      </p>
                      {cart.removedOptions?.length > 0 && (
                        <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                          Çıkarılacaklar:
                          {" " +
                            cart.removedOptions
                              ?.map((opt) => opt.name)
                              .join(",")}
                        </p>
                      )}
                      {cart.extraOptions && (
                        <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                          Patates Seçimi:
                          {cart.extraOptions.name}
                        </p>
                      )}
                      {cart.mealNote && cart.mealNote != null && (
                        <p style={{ lineHeight: 0.8, marginTop: -8 }}>
                          Not:
                          {cart.mealNote}
                        </p>
                      )}
                    </td>

                    <td className="price">
                      {(cart.total + cart.optPrice) * cart.quantity}&#x20BA;
                    </td>
                  </tr>
                </>
              ))}
            {order.note && (
              <div style={{ fontWeight: "bold" }}>
                <td>Not:</td>
                <td>{order.note}</td>
              </div>
            )}
          </tbody>
          <tr>
            <td>Toplam:</td>
            <td></td>
            <td>{order.total} &#x20BA;</td>
          </tr>
          <tr style={{ height: 20 }}></tr>
        </table>
      </div>
    )
  );
});

export default AutoPrint;
